/* Basic Styles Converted from Tailwind */
body {
    font-family: 'Inter', 'Noto Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #111418;
    color: white;
  }
  
  .container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    border-bottom: 1px solid #293038;
  }
  
  .header-logo {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  h2 {
    font-size: 1.25rem; /* text-lg */
    font-weight: bold;
    letter-spacing: -0.015em;
  }
  
  .layout-content {
    display: flex;
    flex-direction: column;
    width: 512px;
    max-width: 960px;
    padding: 40px;
  }
  
  h3 {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
    font-size: 1.5rem; /* text-2xl */
    font-weight: bold;
  }
  
  .form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 8px;
  }
  
  input {
    padding: 15px;
    font-size: 1rem; /* text-base */
    border: 1px solid #3c4753;
    background-color: #1c2126;
    color: white;
    border-radius: 0.75rem; /* rounded-xl */
    outline: none;
  }
  
  input::placeholder {
    color: #9dabb8;
  }
  
  button {
    background-color: #1980e6;
    color: white;
    font-size: 0.875rem; /* text-sm */
    font-weight: bold;
    padding: 10px;
    border-radius: 0.75rem; /* rounded-xl */
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #146bb5;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  .text-sm {
    font-size: 0.875rem;
    color: #9dabb8;
    text-align: center;
  }
  
  label {
    font-size: 0.875rem;
    color: #9dabb8;
    margin-bottom: 8px;
  }